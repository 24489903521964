import React from "react";
import Form from "../../forms/onlyText";

const BuyTheBookForm = ({ name }) => {
  const formTitle =
    name === "form-trial"
      ? "Download the first chapter for free"
      : "Get your free guide to mastering stress";

  return (
    <>
      <div className="container mx-auto">
        <h1 className="font-bold text-lg pb-4">{formTitle}</h1>
        <div className="pr-0 ">
          <Form
            name={name}
            isTrialForm={name === "form-trial"}
            text="Download"
            event="joined_download_first_chapter"
          />
        </div>
      </div>
    </>
  );
};
export default BuyTheBookForm;
